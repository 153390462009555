import { remoteLoad, findFromArray, localTime, translateGps, deleteFromArray, debounce, bd_encrypt } from '@/common/utils'
import { setMapPosition, getMapPosition } from '@/common/cache'
import { getMonitorCache, setMonitorCache, getCustomSetting } from '@/common/cache'
import * as API from '@/api/common'
import { GetObjectIdsByVehicleIDs, AddStar, DeleteStar } from '@/api/common'
import { mapGetters, mapMutations } from 'vuex'
import miment from 'miment'
const DetailVehicle = require('@/router/components' + process.env.VUE_APP_VRC).DetailVehicle
const DetailDevice = require('@/router/components' + process.env.VUE_APP_VRC).DetailDevice
import MapPlace from '@/views/common/map-place'
export default {
  components: {
    DetailVehicle,
    MapPlace,
    DetailDevice
  },
  data() {
    return {
      miment,
      map: null,
      infoWindow: null,
      importantInfo: true,
      refreshBtnBusy: false,
      iconSize: 38,
    }
  },
  watch: {
    'table.show': {
      deep: true,
      handler(val) {
        const cache = getMonitorCache({})
        cache.tableShow = val
        setMonitorCache(cache)
      }
    },
    'table.isWrap': {
      deep: true,
      handler(val) {
        const cache = getMonitorCache({})
        cache.tableWrap = val
        setMonitorCache(cache)
      }
    },
    isShowAddressDefault(val) {
      const cache = getMonitorCache({})
      cache.isShowAddressDefault = val
      setMonitorCache(cache)
    },
    isFixedToolBar(val) {
      if (!this.LayerSwitcherControl) {
        return
      }
      if (val) {
        this.LayerSwitcherControl.domNodes.container[0].style.top = '8px'
      } else {
        this.LayerSwitcherControl.domNodes.container[0].style.top = '48px'
      }
    }
  },
  computed: {
    ...mapGetters(['currentMap'])
  },
  create() {
    const mixinMonitorCache = Object.assign({
      tableShow: false,
      tableWrap: false,
      isShowAddressDefault: true
    }, getMonitorCache())

    if (this.table) {
      this.table.show = mixinMonitorCache.tableShow
      this.table.isWrap = mixinMonitorCache.tableWrap
    }
    this.isShowAddressDefault = mixinMonitorCache.isShowAddressDefault
    if (!this.monitorLeft) {
      // 不显示菜单时隐藏左侧列表
      this.isNavShow = false
      // 不显示菜单时隐藏表格
      this.table.show = false
    }

    if (!this.hasMenu) {
      this.importantInfo = false
    } else if (!this.monitorLeft) {
      this.importantInfo = false
    }

    let viewItems = getCustomSetting('viewItems')
    let viewColumns = getCustomSetting('viewColumns')
    if (!viewItems) {
      viewItems = ['VehicleName']
    }
    if (!viewColumns) {
      viewColumns = ['VehicleName', 'Speed', 'Miles', 'isOnline', 'Acc', 'isAlarm', 'Status', 'GPSTime', 'RcvTime', 'Address']
    }
    this.viewItems = viewItems
    this.viewColumns = viewColumns
  },
  methods: {
    busyRefreshBtn() {
      this.refreshBtnBusy = true
      setTimeout(() => {
        this.refreshBtnBusy = false
      }, REFRESH_BTN_MIN_TIME * 1000)
    },
    saveMapPosition() {
      const position = this.map.getCenter()
      const zoom = this.map.getZoom()
      const text = `${(position.getLng && position.getLng()) || position.lng()},${(position.getLat && position.getLat()) || position.lat()},${zoom},${this.loginInfo.UserID}`
      console.log(text, "数据")
      setMapPosition(text)
      this.$message && this.$message({
        message: this.$t ? this.$t('onlineMonitor.saveMapInfo') : '保存地图位置成功',
        type: 'success'
      })
    },
    loadPosition(type) {
      let center = '88.357837,36.105937,3'
      if (type === 'google') {
        center = '-110.109798,53.973601,2'
      }
      const text = getMapPosition() ? getMapPosition() : center
      return {
        center: [parseFloat(text.split(',')[0]), parseFloat(text.split(',')[1])],
        zoom: parseInt(text.split(',')[2])
      }
    },
    getIconAngle(imageUrl, angle) {
      if (imageUrl.indexOf('Alarm') != -1 ||
        imageUrl.indexOf('Personal') != -1 ||
        imageUrl.indexOf('hat') != -1 ||
        imageUrl.indexOf('RV') != -1 ||
        imageUrl.indexOf('Fifth Wheel') != -1 ||
        imageUrl.indexOf('person') != -1 ||
        imageUrl.indexOf('article') != -1 ||
        imageUrl.indexOf('Trailer') != -1 ||
        imageUrl.indexOf('child') != -1 ||
        imageUrl.indexOf('youth') != -1 ||
        imageUrl.indexOf('middle-aged') != -1 ||
        imageUrl.indexOf('old-man') != -1 ||
        imageUrl.indexOf('other') != -1 ||
        imageUrl.indexOf('child1') != -1 ||
        imageUrl.indexOf('youth1') != -1 ||
        imageUrl.indexOf('middle-aged1') != -1 ||
        imageUrl.indexOf('old-man1') != -1 ||
        imageUrl.indexOf('other1') != -1 ||
        imageUrl.indexOf('child0') != -1 ||
        imageUrl.indexOf('youth0') != -1 ||
        imageUrl.indexOf('middle-aged0') != -1 ||
        imageUrl.indexOf('old-man0') != -1 ||
        imageUrl.indexOf('other0') != -1 ||
        imageUrl.indexOf('cuboid') != -1 ||
        imageUrl.indexOf('round-ellipse') != -1 ||
        imageUrl.indexOf('irregular-form') != -1 ||
        imageUrl.indexOf('Moto') != -1 ||
        imageUrl.indexOf('Excavator') != -1 ||
        imageUrl.indexOf('gs_A+0') != -1 ||
        imageUrl.indexOf('gs_A+1') != -1 ||
        imageUrl.indexOf('gs_A0') != -1 ||
        imageUrl.indexOf('gs_A1') != -1 ||
        imageUrl.indexOf('gs_image') != -1 ||
        imageUrl.indexOf('gs_media') != -1 ||
        imageUrl.indexOf('gs_other0') != -1 ||
        imageUrl.indexOf('gs_other1') != -1 ||
        imageUrl.indexOf('gs_rescue') != -1 ||
        imageUrl.indexOf('gs_shuttlebus') != -1 ||
        imageUrl.indexOf('Marine') != -1) {
        return 0
      } else {
        return angle || 0
      }
    },
    getVehicleName(row) {
      let VehicleName = row.TypeID === 1 ? row.PersonName : (row.TypeID === 2 ? row.ArtName : row.VehicleName)
      let arr = []
      let temp = {
        PeopleName: row.PeopleName,
        VehicleName: VehicleName,
        SIM2: row.SIM2,
        SIM: row.SIM,
        VIN: row.VIN,
        TrackerTypeName: this.$t('dict')[row.TrackerTypeName]
      }
      for (var p in temp) {
        if (this.viewItems.includes(p)) {
          arr.push(temp[p])
        }
      }
      arr = arr.filter(k => !!k)
      if (arr.length == 0) {
        arr = ['--']
      }
      return arr.join('-')
    },
    getTeamStyle(color) {
      let style = {
        backgroundColor: '#fff',
        color: '#000',
        borderColor: color || '#fff',
        borderWidth: '3px'
      }
      return style
    },
    getDataFromTable(ObjectID) {
      let index = this.list.findIndex(k => this.diffCompare(k, {
        ObjectID
      }))
      return this.list[index].data
    },
    startDrag() {
      this.infoWindow.close()
    },
    diffCompare(k, device) { //对比设备ObjectID
      return k.ObjectID === device.ObjectID
    },
    async refreshNow() {
      this.upgrade = 1
      this.busyRefreshBtn()
      this.end_timer()
      try {
        let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
        let endRow = this.table.pageIndex * this.table.pageSize;
        this.fetchAllData().then(deviceInfos => {
          let pageblock = deviceInfos.slice(startRow, endRow)
          this.fetchAddress(deviceInfos, pageblock).then(list => {
            list.forEach(data => {
              this.addDataToMap(data, true)
            })
          })
        })
        if (this.table.show && this.table.viewTable === 2) {
          this.fetchAlarmList()
          this.$refs.deviceNum.fetchSummar()
        }

      } catch (err) {
        console.error(err)
      }
      this.start_timer()
    },
    end_timer() {
      if (!window.countdown_timer) return
      clearInterval(window.countdown_timer)
      window.countdown_timer = null
      this.countdown_timer = null
    },
    removeAllData(res) {
      this.end_timer()
      this.list.forEach(k => {
        k.marker = null
        k.marker_text = null
        k.overlays.forEach(j => {
          j.setMap && j.setMap(null)
        })
      })
      this.list.splice(0)
      this.listItem = []
      this.alarmList.splice(0)
      this.delTaskAllDevice()
      this.map && this.map.clearMap()
    },
    setFitView(map, markers) {
      map.setFitView(markers)
    },
    setFitView_bd(map, markers) {
      let points = []
      if (markers instanceof Array) {
        for (let i = 0; i < markers.length; i++) {
          let k = markers[i]
          if (k instanceof this.BMap.Marker) {
            points.push(k.getPosition())
          } else if (k instanceof this.BMap.Polygon) {
            let pos = k.getPath()
            points = points.concat(pos)
          }
        }
      }
      if (points.length) {
        this.map.setViewport(points)
      }
    },
    panelLinkClick(name, e, ObjectID) {
      let device = this.getDataFromTable(ObjectID)
      if (name === this.$t('onlineMonitor.panelLink4')) {
        this.$router.push(`/online/track?id=${device.ObjectID}`)
      }
      if (name === this.$t('onlineMonitor.panelLink12')) {
        this.$router.push(`/online/tracing?id=${device.ObjectID}`)
      }
      if (name === this.$t('route.riskPlace')) {
        this.$router.push(`/risk/place?id=${device.ObjectID}`)
      }
      if (name === this.$t('onlineMonitor.panelLink2')) {
        if (device.TypeID === 1) {
          this.$refs.detailPerson.open(device.ObjectID)
        } else if (device.TypeID === 2) {
          this.$refs.detailObject.open(device.ObjectID)
        } else {
          this.$refs.detail.open(device.ObjectID)
        }
      }
      if (name === this.$t('onlineMonitor.panelLink5')) {
        this.checkedObjectKeysDefault = [device.ObjectID]
        this.currentCommandDefault = device.MDTTypeID || 10
        this.currentCommandTracker = device.TrackerType || 0
        this.isShowDialogBatch = true
        this.isShowDialogBatchReal = true
        this.currentCommandSingle = false
        this.deviceInfo = device
      }
      if (name === this.$t('onlineMonitor.panelLink3')) {
        this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
      }
      if (name === this.$t('onlineMonitor.panelLink14')) {
        this.clickDialogTire(device)
      }
      if (name === this.$t('onlineMonitor.panelLink1')) {
        API.GetWIFIAndLBSInfoByObjectID(device.ObjectID).then(ret => {
          if (!ret.data.cell.points.length && !ret.data.wifi.points.length) {
            this.$alert(this.$t('onlineMonitor.noLbsTip'))
            return
          }

          this.$refs.dialogCellAndLbs.open(Object.assign(ret.data, {
            VehicleImageUrl: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
            Lon: ret.data.gps.center.Lng,
            Lat: ret.data.gps.center.Lat,
            Direct: device.Direct
          }), miment(device.GPSTime).format(), miment(device.RcvTime).format(), ret.data.type)
        })
      }
      if (name === this.$t('onlineMonitor.panelLink7')) {
        this.sendMessage(device.SIM)
      }
      if (name === this.$t('onlineMonitor.panelLink8')) {
        API.ModifySF(device.ObjectID, 1).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('onlineMonitor.setSF')
          })
          this.addOneDevice(device)
        })
      }
      if (name === this.$t('onlineMonitor.panelLink9')) {
        API.ModifySF(device.ObjectID, 0).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('onlineMonitor.cancelSF')
          })

          this.addOneDevice(device)
        })
      }
      if (name === this.$t('onlineMonitor.panelLink10')) {
        AddStar(device.VehicleID, device.TypeID).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('commonListVehicle.followStatus')
          })
          this.addTaskCar(device.ObjectID)
          this.addOneDevice(device)
        })

      }
      if (name === this.$t('onlineMonitor.panelLink11')) {
        DeleteStar(device.VehicleID, device.TypeID).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('commonListVehicle.followStatus2')
          })
          this.delTaskCar(device.ObjectID)
          this.addOneDevice(device)
        })
      }
    },
    panelHeadClick(e, objectId) {
      let device = this.getDataFromTable(objectId)
      this.panelContext.top = e.target.getBoundingClientRect().bottom
      this.panelContext.left = e.target.getBoundingClientRect().left
      this.panelContext.selectObjectId = objectId
      this.panelContext.objectIdList = device.VehicleList
      this.panelContext.visible = true
    },
    panelHeadButtonClick(ObjectID) {
      let device = this.getDataFromTable(ObjectID)
      this.addTaskCar(device.ObjectID)
    },
    panelVideoClick(index, data) {
      this.openVideo(index, data)
    },
    getDataFromTable(ObjectID) {
      let index = this.list.findIndex(k => this.diffCompare(k, {
        ObjectID
      }))
      return this.list[index].data
    },
    handleCancelAlarm(ObjectID) {
      CancelAlarm(this, ObjectID)
        .then(() => {
          let device = this.getDataFromTable(ObjectID)
          this.currentMap === 'Bmap' ? this.updataInfowindowByData_bd(device) : this.updataInfowindowByData(device)
        })
        .catch(() => { })
    },
    updataInfowindowByData(row) {
      const promise = new Promise(async (resolve) => {
        const reuslt = await this.fetchOneDeviceData2(row.ObjectID, row.VehicleID)
        const device = reuslt[0]
        this.updateDevice = reuslt
        const index = this.list.findIndex(k => this.diffCompare(k, device)) // 图标是否存在于地图
        if (~index) { // 更新地图图标和信息窗状态// 列表信息
          const item = findFromArray(this.list, k => this.diffCompare(k, device))
          item.data = device
          item.ObjectID = device.ObjectID
          item.marker.setIcon(new window.AMap.Icon({
            size: new window.AMap.Size(this.iconSize, this.iconSize),
            image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
            imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
          }))
          item.marker.setAngle(this.getIconAngle(device.VehicleImageUrl, device.Direct))
          item.marker.setPosition([device.Lon, device.Lat])
          item.marker_text.setPosition([device.Lon, device.Lat])
          if (this.infoWindow.getId() == device.ObjectID) {
            this.infoWindow.setContent(this.addDataToMap_getPanel(device))
            this.infoWindow.setPosition([device.Lon, device.Lat])
            this.map.setCenter([device.Lon, device.Lat])
          }
          this.list = Object.assign(this.list, [item])
        }
        if (this.table.show && this.table.viewTable === 2) {
          this.fetchAlarmList() //更新报警列
        }
        this.$refs.deviceNum.fetchSummar() // 更新统计数据
        resolve()
      })
      return promise
    },
    updataInfowindowByData_bd(row) {
      const promise = new Promise(async (resolve) => {
        const reuslt = await this.fetchOneDeviceData2(row.ObjectID, row.VehicleID)
        const device = reuslt[0]
        // console.log(device)
        this.updateDevice = reuslt
        const index = this.list.findIndex(k => this.diffCompare(k, device)) // 图标是否存在于地图
        if (~index) { // 更新地图图标和信息窗状态// 列表信息
          const item = findFromArray(this.list, k => this.diffCompare(k, device))
          item.data = device
          item.ObjectID = device.ObjectID

          item.marker.setIcon(new this.BMap.Icon(require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
            new this.BMap.Size(this.iconSize, this.iconSize), {
            anchor: new this.BMap.Size(this.iconSize / 2, this.iconSize / 2),
            imageSize: new this.BMap.Size(this.iconSize, this.iconSize)
          }))
          item.marker.setRotation(this.getIconAngle(device.VehicleImageUrl, device.Direct))
          item.marker.setPosition(new this.BMap.Point(device.Lon, device.Lat))
          item.marker_text.setPosition(new this.BMap.Point(device.Lon, device.Lat))
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          this.infoWindow.setPosition([device.Lon, device.Lat])
          if (!this.map.getBounds().containsPoint(new this.BMap.Point(device.Lon, device.Lat))) {
            this.map.setCenter(new this.BMap.Point(device.Lon, device.Lat))
          }
          this.list = Object.assign(this.list, [item])
        }
        if (this.table.show && this.table.viewTable === 2) {
          this.fetchAlarmList() //更新报警列
        }
        this.$refs.deviceNum.fetchSummar() // 更新统计数据

        resolve()
      })
      return promise
    },
    addDataToMap_getPanel(device) {
      // 如果在监控列表里面就取最新数据
      const index = this.list.findIndex(k => this.diffCompare(k, device))
      if (~index) {
        device = this.list[index].data
      }
      // 判断是有线设备还是无线设备
      let externalData = null
      if (device.TrackerType !== 0 || device.Battery > 0) {
        // 无线设备
        externalData = {
          trackerType: device.TrackerType,
          battery: device.Battery,
          batteryLife: device.BatteryLife,
          schedule: localTime(device.Schedule)
        }
      }
      let replceStatus = ''
      if (device.StatusDes.search(device.Schedule) > 0) {
        replceStatus = device.StatusDes.replace(new RegExp(device.Schedule, 'g'), localTime(device.Schedule))
      }
      if (this.$i18n.locale === 'fr') {
        replceStatus = translateFR(device.StatusDes)
      }
      let colorArr = ['#65c240', '#1469b8', '#ff4141', '#7f7f7f', '#ffd800', '#ac55eb']
      let titleBackground = colorArr[translateGps(device.TypeID, device, true) - 1]
      // 1：行驶2.熄火 3.静止/怠速 4.离线 5.报警 6.无参考定位

      let viewInfoFun = this.loginInfo.allFuns.includes(5042) || this.loginInfo.allFuns.includes(5044)
      let isVipImg = device.isVip ? `<img src="${require('../../assets/icon/vip.png')}" style="margin-left:8px;height:14px;vertical-align:sub;"/>` : ''
      let links = []
      if (viewInfoFun && this.importantInfo) {
        links.push({ icon: 'iconxiangqing', text: this.$t('onlineMonitor.panelLink2') })
      }
      links.push({ icon: 'iconguiji', text: this.$t('onlineMonitor.panelLink4') })
      if (device.isFocus) {
        links.push({ icon: 'iconyishoucang', text: this.$t('onlineMonitor.panelLink11') })
      } else {
        links.push({
          icon: 'iconshoucang',
          text: this.$t('onlineMonitor.panelLink10')
        })
      }
      if (this.loginInfo.isUser && this.importantInfo && this.loginInfo.allFuns.includes(20120)) {
        links.push({
          icon: 'iconzhiling',
          text: this.$t('onlineMonitor.panelLink5')
        })
      }
      let onPanelUpdate = () => { }
      if (this.currentMap == 'Bmap') {
        onPanelUpdate = () => {
          this.infoWindow.open(this.map, [device.Lon, device.Lat])
        }
      }
      let ObjectID = device.ObjectID
      console.log(device)
      let panel = this.$infowindow.create({
        VehicleName: device.VehicleName,
        personName: device.PersonName,
        ArtName: device.ArtName,
        VehicleID: device.VehicleID,
        TypeID: device.TypeID,
        Temperature: device.Temperature,
        objectId: device.ObjectID,
        Speed: device.Speed,
        PaceSpeed: device.PaceSpeed,
        headClick: true,
        bgColor: true,
        titleBackground,
        lon: device.Lon,
        lat: device.Lat,
        TrackerType: device.TrackerType,
        showWifiInfo: true,
        SF: device.SF,
        GSM: device.GSM,
        GPS: device.GPS,
        Mileage: device.Mileage,
        BattertLife: device.BattertLife,
        Altitude: device.Altitude,
        ToDayMile: device.ToDayMile,
        isFocus: device.isFocus,
        showHeadButton: false,
        showAddrDefault: true,
        PicPath: device.PicPath ? device.PicPath.split("|") : [],
        showDvr: device.MDTModelID === '3169' && !device.soures ? true : false,
        dvrData: device.VideoCircuit ? device.VideoCircuit.split(",") : [],
        onAddressClick: id => this.panelLinkClick(this.$t('onlineMonitor.panelLink1'), null, id),
        disabledAddress: true,
        hideOriginGps: true,
        onAddressUpdate: onPanelUpdate,
        gpsLabelDefault: this.$t('commonPanel.satelLocation'),
        title: device.ObjectName + (device.VehicleList.length > 0 ? '<i class="el-icon-caret-bottom"/>' : null),
        datas: [{
          title: 'deviceID',
          text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType === 0 ? this.$t('onlineMonitor.haslineDevice') : this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
          rawHtml: true
        },
        {
          title: 'gpsTime',
          text: miment(device.GPSTime).format(),
          hidden: true
        },
        {
          title: 'lastLocation',
          text: miment(device.RcvTime).format()
        },
        {
          title: `statsDes`,
          text: replceStatus ? replceStatus : device.StatusDes,
          modelId: `${device.MDTModelID}`
        },
        ],
        personnelData: [{
          title: 'deviceID',
          text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType === 0 ? this.$t('onlineMonitor.haslineDevice') : this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
          rawHtml: true
        },
        {
          title: 'gpsTime',
          text: miment(device.GPSTime).format(),
          hidden: true
        },
        {
          title: 'lastLocation',
          text: miment(device.RcvTime).format()
        },
        {
          title: `statsDes`,
          text: replceStatus ? replceStatus : device.StatusDes,
          modelId: `${device.MDTModelID}`
        },
        {
          title: `personInfo`,
          text: `${device.PersonNam ? device.PersonName + ';' : ''}${device.Age ? device.Age + ';' : ''}${device.Sex === 1 ? this.$t('onlineMonitor.uMale') : this.$t('onlineMonitor.uFemale')}`
        }
        ],
        objectData: [{
          title: 'deviceID',
          text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType === 0 ? this.$t('onlineMonitor.haslineDevice') : this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
          rawHtml: true
        },
        {
          title: 'gpsTime',
          text: miment(device.GPSTime).format(),
          hidden: true
        },
        {
          title: 'lastLocation',
          text: miment(device.RcvTime).format()
        },
        {
          title: `statsDes`,
          text: replceStatus ? replceStatus : device.StatusDes,
          modelId: `${device.MDTModelID}`
        },
        {
          title: `assetInfo`,
          text: `${device.ArtName ? device.ArtName + ';' : ''}${device.ArtNo ? device.ArtNo + ';' : ''}${device.Weight ? device.Weight + this.$t('deviceDetail.Unit2') + ';' : ''}${device.Length + "cm*"}${device.Width + "cm*"}${device.Height + "cm"}`
        }
        ],
        gtime: miment(device.GPSTime).format(),
        rtime: miment(device.RcvTime).format(),
        links,
        onLinkClick: (item, e) => this.panelLinkClick(item, e, ObjectID),
        onHeadClick: (e, objectId) => this.panelHeadClick(e, objectId),
        onHeadButtonClick: () => this.panelHeadButtonClick(ObjectID),
        videoURL: (e) => this.panelVideoClick(e, device),
        isAlarm: device.isAlarm,
        onCancelAlarm: this.handleCancelAlarm,
        paddingLeft: '5em',
        showMore: false,
        externalData: externalData,
        close: true,
        //visible: this.panel_open,
        onClose: () => {
          this.google ? this.infoWindow.close() : this.map.clearInfoWindow()
        },
        onChange: () => { }
      })
      return panel
    },
    addDataToMap_add(device, isAutoRefresh) {
      var marker = new this.AMap.Marker({
        icon: new window.AMap.Icon({
          size: new window.AMap.Size(this.iconSize, this.iconSize),
          image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
          imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
        }),
        position: [device.Lon, device.Lat],
        offset: new this.AMap.Pixel(-this.iconSize / 2, -this.iconSize / 2),
        angle: this.getIconAngle(device.VehicleImageUrl, device.Direct),
        extData: device.ObjectID,
        topWhenClick: true
      })
      var marker_text = new this.AMap.Text({
        text: this.getVehicleName(device),
        position: [device.Lon, device.Lat],
        offset: new this.AMap.Pixel(18, 0),
        extData: device.ObjectID,
        textAlign: 'left',
        topWhenClick: true,
        style: this.getTeamStyle(device.TeamColor)
      })
      if (!isAutoRefresh) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(device.ObjectID)
      } else if (this.infoWindow.getId() == device.ObjectID) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
      }
      marker.on('click', this.carMarkerClick)
      const overlays = []
      const item = {
        data: device,
        marker,
        marker_text,
        overlays,
        ObjectID: device.ObjectID,
        VehicleID: device.VehicleID
      }
      this.list.push(item)
      this.map.add([marker, marker_text])
      if (!isAutoRefresh) {
        this.map.setCenter([device.Lon, device.Lat])
        // 切换地图zoom
        if (this.map.getZoom() < 14) {
          this.map.setZoom(14)
        }
      }
    },
    addDataToMap_change(device, isAutoRefresh, enforce) {
      this.list.forEach(k => {
        if (device.ObjectID === k.ObjectID) {
          k.data.Address = device.Address;
        }
      })
      const item = findFromArray(this.list, k => this.diffCompare(k, device))
      if (!enforce) { //
        if (item.ObjectID === device.ObjectID && item.data.RcvTime === device.RcvTime && item.data.isOnline == device
          .isOnline && isAutoRefresh) {
          return
        }
      }
      item.data = device
      item.ObjectID = device.ObjectID
      item.marker.setIcon(new window.AMap.Icon({
        size: new window.AMap.Size(this.iconSize, this.iconSize),
        image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
        imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
      }))
      item.marker.setAngle(this.getIconAngle(device.VehicleImageUrl, device.Direct))
      item.marker.setPosition([device.Lon, device.Lat])
      item.marker_text.setPosition([device.Lon, device.Lat])
      if (!isAutoRefresh) {
        this.infoWindow.setPosition([device.Lon, device.Lat])
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.open(this.map, [device.Lon, device.Lat])
        this.infoWindow.setId(device.ObjectID)
        this.map.setCenter([device.Lon, device.Lat])
        // 切换地图zoom
        if (this.map.getZoom() < 14) {
          this.map.setZoom(14)
        }
      } else if (this.infoWindow.getId() == device.ObjectID) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.setPosition([device.Lon, device.Lat])
        this.map.setCenter([device.Lon, device.Lat])
      }
    },
    ifNotExistsWindow() {
      if (!this.infoWindow) {
        let infoWindow = new this.AMap.InfoWindow({ isCustom: true, content: '', offset: new this.AMap.Pixel(0, -30) })
        infoWindow.on('close', () => { })
        // 根据getId获取当前监控车辆
        infoWindow.setId = id => infoWindow._id = id
        infoWindow.getId = () => infoWindow._id
        this.infoWindow = infoWindow
      }
    },
    initMap(center = [116.397428, 39.90923], zoom = 11) {
      const promise = new Promise(async resolve => {
        const position = this.loadPosition()
        center = position.center
        zoom = position.zoom
        if (!window.AMap) {
          await remoteLoad(this.$AMAP_URL)
        }
        if (!window.AMapUI) {
          await remoteLoad('//webapi.amap.com/ui/1.1/main.js?v=1.1')
        }
        if (this.currentMap === 'QQmap') {
          this.mapLayer = new window.AMap.TileLayer({
            getTileUrl(x, y, z) {
              let ty = Math.pow(2, z) - 1 - y
              return `//p3.map.gtimg.com/maptilesv2/${z}/${Math.floor(x / 16.0)}/${Math.floor(ty / 16.0)}/${x}_${ty}.png?version=20190101`
            },
            zIndex: 2
          })
        } else if (this.currentMap === 'Google') {
          this.mapLayer = new window.AMap.TileLayer({
            getTileUrl: '//47.88.25.10:10808/vt/lyrs=m@142&hl=zh-CN&gl=cn&src=app&x=[x]&y=[y]&z=[z]&s=Ga',
            zIndex: 2
          })
        } else if (this.currentMap === 'Bing') {
          this.mapLayer = new window.AMap.TileLayer({
            getTileUrl: (x, y, z) => {
              let code = new Array(z)
              let nex = Math.pow(2, z - 1)
              for (let i = 0; i < code.length; i++) {
                let ox = Math.floor(x / nex) % 2
                let oy = (Math.floor(y / nex) % 2) * 2
                code[i] = ox + oy
                nex = nex / 2
              }
              let imgNumber = code.join('')
              return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale == "zh" ? "zh-CN" : "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
            },

            zIndex: 2
          });
        } else if (this.currentMap === 'Openstreet') {
          this.mapLayer = new window.AMap.TileLayer({
            getTileUrl(x, y, z) {
              return `//a.tile.openstreetmap.org/${z}/${x}/${y}.png`
            },
            zIndex: 2
          })
        } else {
          this.mapLayer = new window.AMap.TileLayer({
            getTileUrl: this.getLayerTile,
            zIndex: 2
          });
        }
        var satellite = new window.AMap.TileLayer.Satellite();
        let map = new window.AMap.Map(this.$refs.container, {
          resizeEnable: true,
          buildingAnimation: true,
          zoom,
          center,
          isHotspot: false,
          layers: [satellite],
          zooms: [3, 20],
          expandZoomRange: true
        })
        window.AMapUI.loadUI(['control/BasicControl'], BasicControl => {
          this.LayerSwitcherControl = new BasicControl.LayerSwitcher({
            position: {
              top: '8px',
              right: '10px'
            }
          })
          map.addControl(this.LayerSwitcherControl)
        })
        this.map = map
        this.AMap = window.AMap
        this.ifNotExistsWindow()
        resolve()
      })
      return promise
    }
  }
}