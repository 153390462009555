<template>
  <div class="i-main-content-wrapper tool--none" :class="{ 'fullscreen-item': isFullScreen }">
    <div class="tree-nav" :class="{ 'nav-in': isSlider }">
      <tree-nav ref="aside" :show-arrow="true" :upgrade='upgrade' :updateDevice="updateDevice" @click-device="clickDevice"
        @command="commandDevice" @click-batch="clickBatch" @handler-all="clickAll" v-show="isSlider" show-count>
      </tree-nav>
      <div class="slideArrow" @click="() => { isSlider = !isSlider }">
        <span :class="isSlider ? '' : 'rote'">
          <img :src="require('../../assets/images/gs-arrow2.png')" alt="">
        </span>
      </div>
    </div>
    <div :class="currentMap === 'Amap' ? 'subBlock glmap' : 'subBlock'">
      <div class="map"
        :class="{ 'is-panoopen': isShowRealScene, 'is-animate': isAnimating, 'is-FixedToolbar': isFixedToolBar }"
        ref="container" v-show="!(currentMap === 'Bmap' && glBmap)" id="container">
      </div>
      <div class="map--tip" :class="{ 'isNavShow': isNavShow }">
        <transition-group name="slidegroup">
          <div class="countdown" v-if="list.length > 0" key="1">
            <i class="el-icon-time" style="margin-right:4px;"></i>
            {{ $t('onlineMonitor.refreshTip', { count: countdown }) }}
            <el-button @click="refreshNow" type="text" :disabled="isLoadingMapData || refreshBtnBusy">
              {{ $t('common.refresh') }}</el-button>
            <el-button @click="end_timer" v-if="isShowPause && countdown_timer"
              :disabled="isLoadingMapData || refreshBtnBusy" type="text">{{ $t('onlineMonitor.refreshAction') }}
            </el-button>
            <el-button @click="start_timer" v-if="isShowPause && !countdown_timer"
              :disabled="isLoadingMapData || refreshBtnBusy" type="text">{{ $t('onlineMonitor.refreshResume') }}
            </el-button>
          </div>
        </transition-group>
      </div>
      <div class="v-detail" :class="{ 'isHide': !isNavShow }">
        <drag-wrapper v-if="table.show" @end-drag="({ y }) => table.height -= y" @start-drag="startDrag"></drag-wrapper>
        <arrow-button @click="table.show = !table.show" class="toggleTable" :position="table.show ? 'bottom' : 'top'">
        </arrow-button>
        <div class="tipGroup">
          <mapTips />
          <deviceNum :upgrade='upgrade' @enter-alarm="activeAlarmList" @handlerAll="clickAll" ref="deviceNum" />
        </div>
        <div v-if="table.show">
          <template v-if="table.viewTable === 1">
            <el-table border stripe key="table1" size="mini" :data="tableList" :height="table.height"
              :default-sort="{ prop: tableSort.name, order: tableSort.orderBy }" @row-dblclick="rowDbClick"
              @sort-change="changeSort" class="tracktable" style="width: 100%">
              <el-table-column prop="PeopleName" sortable :label="$t('vehicleDetail.peopleName')" width="100"
                v-if="viewColumns.includes('PeopleName')">
                <template slot-scope="{row}">{{ row.PeopleName || '--' }}</template>
              </el-table-column>
              <el-table-column prop="VehicleName" sortable :label="$t('onlineMonitor.deviceName')" width="120"
                v-if="viewColumns.includes('VehicleName')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">
                  <span v-if="row.VehicleList[0].TypeID === 1">{{ row.PersonName }}</span>
                  <span v-else-if="row.VehicleList[0].TypeID === 2">{{ row.ArtName }}</span>
                  <span v-else>{{ row.VehicleName }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="VIN" sortable :label="$t('vehicleDetail.vehicleId')" width="140"
                v-if="viewColumns.includes('VIN')">
                <template slot-scope="{row}">{{ row.VIN || '--' }}</template>
              </el-table-column>
              <el-table-column prop="SIM2" sortable :label="$t('deviceManage.deviceID')" width="140"
                v-if="viewColumns.includes('SIM2')">
                <template slot-scope="{row}">{{ row.SIM2 || '--' }}</template>
              </el-table-column>
              <el-table-column prop="TrackerTypeName" sortable :label="$t('deviceManage.TrackerTypeName')" width="70"
                v-if="viewColumns.includes('TrackerTypeName')">
                <template slot-scope="{row}">{{ row.TrackerTypeName ? $t('dict')[row.TrackerTypeName] : '--' }}</template>
              </el-table-column>
              <el-table-column prop="Speed" sortable :label="$t('onlineMonitor.speed') + '(km/h)'" width="90"
                v-if="viewColumns.includes('Speed')">
                <template slot-scope="{row}">{{ row.Speed }}</template>
              </el-table-column>
              <el-table-column prop="Mileage" sortable :label="$t('onlineMonitor.totalMile') + '(km)'" width="90"
                v-if="viewColumns.includes('Miles')">
                <template slot-scope="{row}">{{ row.Mileage }}</template>
              </el-table-column>
              <el-table-column :key="80" prop="isOnline" sortable :label="$t('deviceManage.online')" width="55"
                v-if="viewColumns.includes('isOnline')">
                <template slot-scope="{row}">
                  <p class="square" :class="{ isOnline: !!row.isOnline }"></p>
                </template>
              </el-table-column>
              <el-table-column :key="90" prop="Status" sortable :label="$t('deviceManage.acc')" width="55"
                v-if="viewColumns.includes('Acc')">
                <template slot-scope="{row}">
                  <template v-if="row.TypeID === 0">
                    <template v-if="row.isOnline">
                      <p v-if="row.Status" class="imgcolumn">
                        <template v-if="!!(row.GPSFlag % 2)">
                          <span v-if="!!row.Speed" class="status" alt="启动,行驶" :title="$t('deviceManage.keyOnRoad')">
                            <i class="s1"></i>
                          </span>
                          <span v-else class="status" alt="启动,怠速" :title="$t('deviceManage.keyDaisu')">
                            <i class="s2"></i>
                          </span>
                        </template>
                        <span v-else class="status" alt="启动,参考定位" :title="$t('deviceManage.keyRef')">
                          <i class="s3"></i>
                        </span>
                      </p>
                      <p v-else class="imgcolumn">
                        <template v-if="!!(row.GPSFlag % 2)">
                          <span v-if="!!row.Speed" class="status" alt="行驶">
                            <i class="s1"></i>
                          </span>
                          <span class="status" alt="熄火" :title="$t('deviceManage.keyNoSpeed')" v-else>
                            <i class="s4"></i>
                          </span>
                        </template>
                        <span v-else class="status" alt="熄火,参考定位" :title="$t('deviceManage.keyNoSpeedRef')">
                          <i class="s5"></i>
                        </span>
                      </p>
                    </template>
                    <template v-else>
                      <p class="imgcolumn">
                        <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                          <i class="s6"></i>
                        </span>
                      </p>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="row.isOnline">
                      <template v-if="!!(row.GPSFlag % 2)">
                        <p class="imgcolumn">
                          <span v-if="!!row.Speed" class="status" alt="运动" :title="$t('deviceManage.keyMov')">
                            <i class="s1"></i>
                          </span>
                          <span v-else class="status" alt="静止" :title="$t('deviceManage.keyStop')">
                            <i class="s2"></i>
                          </span>
                        </p>
                      </template>
                      <template v-else>
                        <p class="imgcolumn">
                          <span class="status" alt="参考定位" :title="$t('deviceManage.keyNoGps')">
                            <i class="s5"></i>
                          </span>
                        </p>
                      </template>
                    </template>
                    <template v-else>
                      <p class="imgcolumn">
                        <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                          <i class="s6"></i>
                        </span>
                      </p>
                    </template>
                  </template>
                </template>

              </el-table-column>
              <el-table-column :key="100" prop="isAlarm" sortable :label="$t('deviceManage.alarm')" width="55"
                v-if="viewColumns.includes('isAlarm')">
                <template slot-scope="{row}">
                  <p v-if="!!row.isAlarm" class="imgcolumn"><img src="../../assets/icon/报警.gif" alt="报警"
                      :title="$t('deviceManage.keyAlarm')" /></p>
                  <p v-else class="imgcolumn"><img src="../../assets/icon/正常.gif" alt="正常"
                      :title="$t('deviceManage.keyNormarl')" /></p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('onlineMonitor.statsDes')" prop="StatusDes" sortable
                :show-overflow-tooltip="!table.isWrap" v-if="viewColumns.includes('Status')">
                <template slot-scope="{row}">
                  <!-- color:#FF5722; -->
                  <span style="line-height:16px;" class="status">{{ row.StatusDes }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GPSTime" sortable :sort-by="['GPSTime']" :label="$t('onlineMonitor.gpsTime')"
                width="140" v-if="viewColumns.includes('GPSTime')">
                <p slot-scope="{row}">{{ miment(row.GPSTime).format() }}</p>
              </el-table-column>
              <el-table-column prop="RcvTime" sortable :label="$t('onlineMonitor.rcvTime')" width="140"
                v-if="viewColumns.includes('RcvTime')">
                <p slot-scope="{row}">{{ miment(row.RcvTime).format() }}</p>
              </el-table-column>
              <el-table-column :label="$t('onlineMonitor.place')" sortable prop="Address"
                :show-overflow-tooltip="!table.isWrap" width="380" v-if="viewColumns.includes('Address')">
                <template slot-scope="{row}">
                  <div>
                    <span @click.stop='toggleAddress(row, false)' class="pointer" v-if="row.showAddress">
                      {{ row.Address }}</span>
                    <span @click.stop='toggleAddress(row, true)' class="pointer" v-else>{{ row.Lon }},{{ row.Lat }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="{row}">
                  <span class="i-link" @click.stop="removeOneDevice(row)">{{ $t('onlineMonitor.remove') }}</span>
                </template>
              </el-table-column>
            </el-table>

            <el-pagination key="pager1" class="v-pager" small @size-change="handleSizeChange"
              @current-change="handleCurrentChange" :current-page.sync="table.pageIndex" :page-size="table.pageSize"
              :page-sizes="[10, 20, 50, 100, 250]" layout="total, jumper, prev, pager,sizes, next" :total="list_length">
            </el-pagination>
          </template>
          <template v-if="table.viewTable === 2">
            <el-table border stripe v-loading="!pageAlarmList.length && alarmTableLoading" key="table2" size="mini"
              :data="pageAlarmList" :height="table.height" @row-dblclick="rowDbClickAlarm" class="tracktable"
              style="width: 100%">
              <el-table-column prop="EventTime" :label="$t('onlineMonitor.gpsTime')" width="140">
                <p slot-scope="{row}">{{ miment(row.EventTime).format() }}</p>
              </el-table-column>
              <el-table-column prop="VehicleNum" :label="$t('onlineMonitor.deviceName')" width="180"
                :show-overflow-tooltip="!table.isWrap"></el-table-column>

              <el-table-column prop="AlarmType" :label="$t('onlineMonitor.alarmType')" width="200">
                <p slot-scope="{row}" style="color:#FF5722;">{{ row.AlarmType }}</p>
              </el-table-column>

              <el-table-column prop="Speed" :label="$t('onlineMonitor.speed')" width="70">
                <template slot-scope="{row}">{{ row.Speed + " km/h" }}</template>
              </el-table-column>

              <el-table-column prop="Direct" :label="$t('onlineMonitor.directoin')" width="85">
                <template slot-scope="{row}">{{ getDirectionDescription(row.Direct, $i18n.locale) }}</template>
              </el-table-column>

              <el-table-column :label="$t('onlineMonitor.place')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">
                  <span-address :lon="row.Lon" :lat="row.Lat" :show-default="isShowAddressDefault"></span-address>
                </template>
              </el-table-column>

              <el-table-column :label="$t('onlineMonitor.enterprise')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">{{ row.HoldName }}</template>
              </el-table-column>

              <el-table-column width="100">
                <template slot-scope="{row}">
                  <span class="i-link" @click.stop="rowCancelAlarm(row)">{{ $t('other.cancelAlarm') }}</span>
                </template>
              </el-table-column>

            </el-table>
            <el-pagination key="pager2" class="v-pager" small :current-page.sync="alarmPageIndex"
              :page-size="alarmPageSize" layout="total, jumper, prev, pager, next" :total="alarmList.length">
            </el-pagination>
          </template>

          <div class="table-check">
            <el-button type="text" class="flat tool-btn primary--text imr-4" @click="removeAllData" v-ripple><i
                class="el-icon-delete" /> {{ $t('onlineMonitor.emptyList') }}</el-button>
            <el-checkbox v-model="isShowAddressDefault">{{ $t('onlineMonitor.showAddress') }}</el-checkbox>
            <el-checkbox v-model="table.isWrap">{{ $t('onlineMonitor.lineFeed') }}</el-checkbox>
            <img class="table-exchange" src="../../assets/icon/exchange.gif"
              @click="table.viewTable = 3 - table.viewTable" />
          </div>

        </div>
      </div>
    </div>
    <detailPolyon ref="dialogPolyon" @openPolygonlist="openDialogList"></detailPolyon>
    <detailCircle ref="dialogCircle" @openPolygonlist="openDialogList"></detailCircle>
    <detailLine ref="dialogLine" @openPolygonlist="openDialogList"></detailLine>
    <detailPolyonList ref="dialogPolyonList"></detailPolyonList>
    <!-- 指令发送 弹窗 -->
    <el-dialog :visible.sync="isShowDialogBatch" :title="$t('onlineMonitor.batchCommand')"
      custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-gs-dialog" append-to-body v-if="isShowDialogBatchReal"
      @closed="isShowDialogBatchReal = false">
      <batch-command :current-type="currentCommandTracker" :current-command-default="currentCommandDefault"
        :checked-object-keys-default="checkedObjectKeysDefault" :current-command-single="currentCommandSingle"
        :device-info="deviceInfo"></batch-command>
    </el-dialog>
    <!-- 基站/卫星 弹窗 -->
    <dialog-cellandlbs ref="dialogCellAndLbs"></dialog-cellandlbs>
    <detail-vehicle ref="detailVehicle"></detail-vehicle>
    <detail-device-info ref="detailDeviceInfo"></detail-device-info>
    <detail-device ref="detail" @success="handlerRefresh"></detail-device>
    <detail-device-object ref="detailObject" @success="handlerRefresh"></detail-device-object>
    <detail-device-person ref="detailPerson" @success="handlerRefresh"></detail-device-person>
    <!-- 面板 导入KML 文件生成路径 -->
    <mapGame :upgrade='upgrade'></mapGame>
    <mapPolygon :map="map" ref="mapPoi" :class="$i18n.locale" :info-window="infoWindow" :object-id="null"
      @relation="handlerRelation" @roadExprot="handlerExprotRoad" :showRoad="true" @openPolygonlist="openDialogList">
    </mapPolygon>
    <!-- 围栏-导入设置-->
    <polygonExport :map="map" :info-window="infoWindow" ref="mapExprot" @relation="handlerRelation"></polygonExport>

  </div>
</template>
<script>
import miment from 'miment'
import * as API from '@/api/common'
import { CancelAlarm } from '@/common/other'
import { mapGetters, mapMutations } from 'vuex'
import mixinMonitor from '@/views/mixins/mixin-monitor.sport'
import TreeNav from '@/views/common/tree-option'
import ArrowButton from '@/components/arrow-button'
import DragWrapper from '@/components/drag-wrapper'
import deviceNum from './components/deviceNum'
import detailPolyon from '@/views/risk/detail/detail-polygon' //关联多边形围栏
import detailCircle from '@/views/risk/detail/detail-circle' //关联圆形围栏
import detailLine from '@/views/risk/detail/detail-line' //关联线路
import detailPolyonList from '@/views/risk/detail/detail-polygonlist'
import BatchCommand from '@/views/batch/batch-command'
import DialogCellandlbs from './components/dialog-cellandlbs'
import DetailDeviceInfo from '../manage/detail/detail-device-info'
import DetailDevice from '../manage/detail/detail-device'
import DetailDeviceObject from '../manage/detail/detail-device.object'
import DetailDevicePerson from '../manage/detail/detail-device.person'
import mapGame from '@/views/common/map-game'
import mapPolygon from '@/views/common/map-polygon'
import polygonExport from '@/views/common/polygon-export'

const IS_DEBUG = process.env.NODE_ENV === 'development'
const REFRESH_SPACE_TIME = 15 // 单位：秒
export default {
  name: 'mapControl',
  components: {
    TreeNav,
    ArrowButton,
    DragWrapper,
    deviceNum,
    detailPolyon,
    detailCircle,
    detailLine,
    detailPolyonList,
    BatchCommand,
    DialogCellandlbs,
    DetailDeviceInfo,
    DetailDevice,
    DetailDeviceObject,
    DetailDevicePerson,
    mapGame,
    mapPolygon,
    polygonExport,
  },
  mixins: [mixinMonitor],
  computed: {
    list_length() {
      return this.list.length || 0
    },
    tableList() {
      let sheetList = this.list.map(k => k.data).slice((this.table.pageIndex - 1) * this.table.pageSize, this.table
        .pageIndex * this.table.pageSize)
      return sheetList
    },
    pageAlarmList() {
      return this.alarmList.map(k => k).splice((this.alarmPageIndex - 1) * this.alarmPageSize, this.alarmPageSize)
    },
    ...mapGetters(['loginInfo', 'taskList', 'currentHoldId', 'currentMap'])
  },
  data() {
    return {
      miment,
      REFRESH_SPACE_TIME, //系统定义倒计时
      isShowPause: IS_DEBUG, //开发环境暂停计时器
      isLoadingMapData: false, // 是否在加载地图
      countdown_timer: null, // 是否开启定时器
      isSlider: true, // 侧边栏 
      checkedObjectKeysDefault: [], // 批量指令默认设备
      currentCommandTracker: 0,
      currentCommandDefault: 10, // 批量指令默认指令类型
      isShowDialogBatch: false, // 发送指令弹窗
      isShowDialogBatchReal: false,
      updateDevice: [],
      upgrade: 15, //倒计时同步时间
      alarmTableLoading: false,
      alarmList: [], // 报警列表 50s刷新每次
      alarmPageIndex: 1,
      alarmPageSize: 20,
      isShowAddressDefault: true, // 报警列表是否翻译地址
      isShowRealScene: false, //显示实景
      isAnimating: false,
      isFixedToolBar: true,
      isFullScreen: false,
      isNavShow: true, // 显示/隐藏详情
      list: [], // 下拉设备列表1
      listItem: [], //加载列表数据
      table: {
        show: false,
        pageIndex: 1,
        pageSize: 10,
        isWrap: false,
        viewTable: 1,
        height: 224
      },
      tableSort: {
        name: '',
        orderBy: ''
      },
      countdown: 15, // 倒计时
      timer_activeAlarmList: null,
      alarmTableLoading: false,
      alarmList: [], // 报警列表 50s刷新每次
      alarmPageIndex: 1,
      alarmPageSize: 20,
      deviceInfo: {},
      poiVisible: false,
      poiEditStatus: 0, // 1.编辑工具/绘制完成（待保存/重新编辑）  2.开始绘制  3.编辑工具打开  4.映射围栏到地图上面
      polygonData: {
        points: [], // 编辑完成的点数据
        polygon: '', // 围栏对象
        item: '', //对象数据
      },
      mousetool: null, // 绘制笔对象
      msTool: null, // 编辑围栏对象
      polygonType: 1, // 1.多边形 2.圆形 3.矩形
      roadOverlays: [],
    }
  },
  created() {
    this.delTaskAllDevice()
    this.regionOverlays = []
    this.poiOverlays = []
    this.regionOverlaysJuhe = []
  },
  mounted() {
    this.initMap().then(this.routerEnterHandler)
    this.$refs.mapPoi && (this.$refs.mapPoi.visible = true)
  },
  methods: {
    ...mapMutations({
      'delTaskCar': 'DEL_TASK_CAR',
      'addTaskCar': 'ADD_TASK_CAR',
      'addTaskDevice': 'ADD_TASK_DEVICES',
      'delTaskDevice': 'DEL_TASK_DEVICES',
      'delTaskAllDevice': 'DEL_ALL_TASK_DEVICES',
      'setAmapGl': 'SET_AMAP_GL',
      'setBmapGl': 'SET_BMAP_GL'
    }),
    openBatchCommand() {
      this.isShowDialogBatch = true
      this.isShowDialogBatchReal = true
      this.currentCommandSingle = true
    },
    openDialogList(item) {
      this.$refs.dialogPolyonList.open(item)
    },
    start_timer() {
      this.countdown = REFRESH_SPACE_TIME
      const handler = async () => {
        this.upgrade = this.countdown //同步传值倒计时
        if (this.countdown === 1) {
          clearInterval(window.countdown_timer)
          window.countdown_timer = null
          try {
            let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
            let endRow = this.table.pageIndex * this.table.pageSize;
            this.fetchAllData().then(deviceInfos => {
              let pageblock = deviceInfos.slice(startRow, endRow)
              this.fetchAddress(deviceInfos, pageblock).then(list => {
                list.forEach(data => {
                  this.addDataToMap(data, true)
                })
              })
            })
            if (this.table.show && this.table.viewTable === 2) {
              this.fetchAlarmList()
              this.$refs.deviceNum.fetchSummar()
            }
          } catch (err) {
            console.error(err)
          }
          clearInterval(window.countdown_timer)
          window.countdown_timer = setInterval(handler, 1000)
        }
        this.countdown = this.countdown > 1 ? this.countdown - 1 : REFRESH_SPACE_TIME
      }
      clearInterval(window.countdown_timer)
      window.countdown_timer = setInterval(handler, 1000)
      this.countdown_timer = true
    },
    toggleAddress(row, bool) {
      let that = this;
      that.list.forEach((k, index) => {
        if (row.ObjectID === k.ObjectID) {
          that.list[index].data.showAddress = bool;
        }
      })
    },
    rowDbClick(row) {
      this.addDataToMap(row, false)
    },
    rowDbClickAlarm(row) {
      this.clickBatch([row.ObjectID])
    },
    rowCancelAlarm(row) {
      CancelAlarm(this, row.ObjectID).then(() => {
        this.updataInfowindowByData(row)
      }).catch(() => { })
    },
    changeSort(e) {
      this.tableSort.name = e.prop
      this.tableSort.orderBy = e.order
    },
    handleSizeChange(val) {
      this.table.pageSize = val;
      let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
      let endRow = this.table.pageIndex * this.table.pageSize;
      let pages = this.list.slice(startRow, endRow).map(k => k.ObjectID);
      this.fetchAllData(pages).then(deviceInfos => {
        // let pageblock = deviceInfos.slice(startRow,endRow)
        this.fetchAddress(deviceInfos, deviceInfos).then(list => {
          list.forEach(data => {
            this.addDataToMap(data, false)
          })
          // 切换地图中心
          const markers = this.list.filter(k => deviceInfos.includes(k.ObjectID)).map(k => k.marker)
          if (this.currentMap === 'Bmap') {
            this.setFitView_bd(this.map, markers)
          } else {
            this.setFitView(this.map, markers)
          }
          // 点击单个设备ID时打开信息框
          if (list.length === 1) {
            // this.openInfowindowByData(list[0])
          } else {
            this.infoWindow.close()
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.table.pageIndex = val;
      let startRow = (val - 1) * this.table.pageSize;
      let endRow = val * this.table.pageSize;
      let pages = this.list.slice(startRow, endRow).map(k => k.ObjectID);
      this.fetchAllData(pages).then(deviceInfos => {
        // let pageblock = deviceInfos.slice(startRow,endRow)
        this.fetchAddress(deviceInfos, deviceInfos).then(list => {
          list.forEach(data => {
            this.addDataToMap(data, false)
          })
          // 切换地图中心
          const markers = this.list.filter(k => deviceInfos.includes(k.ObjectID)).map(k => k.marker)
          if (this.currentMap === 'Bmap') {
            this.setFitView_bd(this.map, markers)
          } else {
            this.setFitView(this.map, markers)
          }
          // 点击单个设备ID时打开信息框
          if (list.length === 1) {
            // this.openInfowindowByData(list[0])
          } else {
            this.infoWindow.close()
          }
        })
      })
    },
    handlerExprotRoad() {
      this.$refs.mapExprot.open()
    },
    handlerRelation(data) {
      let temp = {
        type: data.type,
        obj: {
          RegionID: data.type === 1 ? data.obj.RegionID : data.obj.CircleID,
          PolygonName: data.type === 1 ? data.obj.RegionName : data.obj.CircleName,
          tag: data.tag || []
        }
      }
      if (data.type === 1) {
        this.$refs.dialogPolyon.open(temp)
      } else if (data.type === 2) {
        this.$refs.dialogCircle.open(temp)
      } else if (data.type === 3) {
        this.$refs.dialogLine.open(temp)
      }
    },
    handlerRefresh(e) {
      this.addOneDevice(e)
    },
    refreshAlarmList() {
      if (this.timer_activeAlarmList) {
        return
      }
      this.timer_activeAlarmList = setInterval(() => {
        this.fetchAlarmList()
        this.$refs.deviceNum.fetchSummar()
      }, 50000)

      this.$once('hook:beforeDestroy', () => {
        console.log('clear timer_activeAlarmList')
        clearInterval(this.timer_activeAlarmList)
      })
    },
    // 打开并加载报警列表、每50s刷新一次
    activeAlarmList() {
      if (this.table.show && this.table.viewTable === 2) {
        this.$refs.deviceNum.fetchSummar()
        this.fetchAlarmList()
      } else {
        // 打开报警列表
        if (!this.timer_activeAlarmList) {
          this.refreshAlarmList()
        }
        this.table.show = true
        this.table.viewTable = 2
      }
    },
    routerEnterHandler() {
      if (this.$route.query.action === 'addTaskList') {
        if (this.$route.query.objectid) {
          this.addTaskListOne(this.$route.query.objectid)
        } else {
          this.addTaskList()
        }
        this.$router.replace('/online/monitor')
      }
    },
    commandDevice(device) {
      this.checkedObjectKeysDefault = [device.ObjectID]
      this.currentCommandDefault = device.MDTTypeID || 10
      this.isShowDialogBatch = true
      this.isShowDialogBatchReal = true
    },
    clickAll(type, hold) {
      // let data = this.list.filter(k => !!k.RcvTime).map(k => k.ObjectID)
      // 改为获取全部设备ID
      this.fetchAll(type, hold).then(res => {
        let data = res.filter(k => k.RcvTime).map(j => j.ObjectID)
        if (data.length > 500) {
          this.$message({ message: this.$t('onlineMonitor.monitorAllTip'), type: 'info' })
          data = data.slice(0, 500).reverse()
        }
        data.length && this.clickBatch(data, true)
      })
    },
    clickDevice(item, bool) { // 点击设备列表（添加/删除到地图）
      if (bool) {
        this.addOneDevice(item)
      } else {
        this.removeOneDevice(item)
      }
    },
    clickBatch(ObjectIDList, bool, enforce) {
      if (bool) {
        this.removeAllData()
      }
      this.isShowVideo = false;
      this.listItem = this.listItem.concat(ObjectIDList)
      let arrNew = new Set(this.listItem); //通过set集合去重
      this.listItem = Array.from(arrNew);
      let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
      let endRow = this.table.pageIndex * this.table.pageSize;
      this.fetchAllData(ObjectIDList).then(deviceInfos => {
        let pageblock = deviceInfos.slice(startRow, endRow)
        this.fetchAddress(deviceInfos, pageblock).then(list => {
          list.forEach(data => {
            this.addDataToMap(data, list.length !== 1, enforce)
          })
          // 切换地图中心
          const markers = this.list.filter(k => ObjectIDList.includes(k.ObjectID)).map(k => k.marker)
          if (this.currentMap === 'Bmap') {
            this.setFitView_bd(this.map, markers)
          } else {
            this.setFitView(this.map, markers)
          }

          // 点击单个设备ID时打开信息框
          if (list.length === 1) {
            // this.openInfowindowByData(list[0])
          } else {
            this.infoWindow.close()
            // markers.forEach(k => {
            //   k.setAnimation('AMAP_ANIMATION_DROP')
            // })
          }
        })
      })
    },
    addOneDevice(item, autoFresh, enforce) {
      // 添加一辆车
      const promise = new Promise(async (resolve) => {
        const device = await this.fetchOneDeviceData2(item.ObjectID, item.VehicleID)
        let arr = [];
        arr.push(device[0]);
        this.fetchAddress(arr, arr).then(list => {
          this.addDataToMap(list[0], autoFresh, enforce)
        })
        resolve()
      })
      return promise
    },
    removeOneDevice(device) {
      const promise = new Promise((resolve) => {
        const index = this.list.findIndex(k => this.diffCompare(k, device))
        this.list[index].marker.setMap(null)
        this.list[index].marker_text.setMap(null)
        this.list[index].overlays.forEach(j => {
          j.setMap(null)
        })
        this.listItem = this.listItem.filter(item => item !== device.ObjectID)
        this.list.splice(index, 1)
        if (this.infoWindow.getId() == device.ObjectID) {
          this.infoWindow.close()
        }
        if (this.list.length === 0 && this.listItem.length === 0) {
          this.table.show = false
        }
        this.delTaskDevice(device.ObjectID)
        resolve()
      })
      return promise
    },
    addDataToMap(device, isAutoRefresh, enforce) {
      if (!device.RcvTime) {
        this.$message({ message: this.$t('onlineMonitor.noPositionTip'), type: 'info' })
        return
      }
      const index = this.list.findIndex(k => this.diffCompare(k, device))
      return ~index ? this.addDataToMap_change(device, isAutoRefresh, enforce) : this.addDataToMap_add(device, isAutoRefresh, enforce)
    },
    fetchAll(type, hoid) {
      let fitler = [
        { PropertyName: "O.SIM2", Value: "", Operation: 5, Id: null, label: "设备ID" },
        { PropertyName: "O.VehicleNum", Value: "", Operation: 5, Id: null, label: "名称" }
      ]
      const promise = new Promise((resolve) => {
        API.QueryComprehenList(1, 1000, '', 'ASC', fitler, hoid || [this.currentHoldId], type, false, null).then(ret => {
          this.loading = false
          this.tableLoading = false
          resolve(ret.data.list)
        })
      })
      return promise
    },
    fetchAllData(list) {
      list = list || this.list.map(k => k.ObjectID)
      let items = list
      // 加载监控数据并刷新
      const promise = new Promise((resolve, reject) => {
        this.isLoadingMapData = true
        if (items.length) {
          API.QueryDeviceOnlineDataByObjectIDs(items).then(ret => {
            this.parameter.DeviceID = ret.data[0].SIM2;
            this.parameter.VN = ret.data[0].ObjectName;
            this.parameter.ModelID = ret.data[0].MDTModelID;
            if (ret.data.errCode) {
              this.$message({ message: ret.data.errMsg, type: 'error' })
              return reject(ret.data)
            }
            let result = ret.data
            resolve(result)
          }).catch(err => {
            reject(err)
          }).finally(() => {
            this.isLoadingMapData = false
          })
        } else {
          let arr = []
          resolve(arr)
          this.isLoadingMapData = false
        }
      })
      return promise
    },
    fetchOneDeviceData2(ObjectID) {
      const promise = new Promise((resolve, reject) => {
        this.isLoadingMapData = true
        API.QueryDeviceOnlineDataByObjectIDs([ObjectID]).then(ret => {
          this.isLoadingMapData = false
          if (ret.data.errCode) {
            this.$message({ message: ret.data.errMsg, type: 'error' })
            return reject(ret.data)
          }
          resolve(ret.data)
        }).catch(err => {
          reject(err)
        })
      })
      return promise
    },
    fetchAddress(list, pages) {
      let temp = [];
      pages.forEach(k => {
        let item = { 'Lon': k.sLon || k.data && k.data.sLon || null, 'Lat': k.sLat || k.data && k.data.sLat || null, 'ObjectID': k.ObjectID }
        temp.push(item)
      })
      const promise = new Promise((resolve) => {
        API.GetAddressList(temp).then((ret) => {
          if (ret.data.errCode) {
            return reject(ret.data)
          }
          list.forEach(k => {
            ret.data.forEach(j => {
              if (j.ObjectId === k.ObjectID) {
                k.Address = j.Address
                if (j.showAddress === undefined) {
                  k.showAddress = this.isShowAddressDefault
                } else {
                  k.showAddress = j.showAddress
                }
              }
            })
          })
          resolve(list)
        })

      })
      return promise
    },
    fetchAlarmList() {
      this.alarmTableLoading = true
      API.QueryAlarms().then(ret => {
        this.alarmTableLoading = false
        if (!ret.data.errCode) {
          this.alarmList = ret.data.list
        }
      }).catch(() => {
        this.alarmTableLoading = false
        console.error('fetchAlarmList error')
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";

// // 左导航栏z-index
// $page-nav-zindex: 112;
// // 左导航栏z-index
// $page-nav-tool-zindex: 112;

.tool-btns {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // z-index: 200;
  z-index: $page-nav-tool-zindex;
  height: 40px;
  background-color: rgba(255, 255, 255, .7);
  float: right;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: $page-main-tool-height;
  border-bottom: 1px solid rgba($--color-primary, .12);

  &.is-FixedToolbar {
    background-color: rgba(255, 255, 255, 1);
  }

  &>.el-button:not(.first) {
    margin-left: 10px;
    font-size: 13px;
  }

  &.isNavShow {
    left: 250px;
    width: calc(100% - 250px);
  }

  // &.isShowRealScene {
  //   //transform: translateX(-40px);
  // }
  .loading-container {
    position: absolute;
    z-index: 1;
    height: 40px;
    width: 40px;
    left: 0;
    top: 0;

    &__inner {
      height: 40px;
      width: 40px;

      & ::v-deep .el-loading-mask {
        background-color: transparent;

        .el-loading-spinner {
          margin-top: -12px;

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
}

.tool-btn {
  font-size: 14px;
  padding: 4px;
}

.i-main-content-wrapper {
  position: relative;
  display: flex;
  align-items: stretch;

  .tree-nav {
    background-color: #FFF;
    // width: 540px;
    // flex-shrink: 0;
    // overflow-y: auto;
    position: relative;
    // z-index: 112;
    z-index: $page-nav-zindex;

    .slideArrow {
      width: 20px;
      height: 72px;
      line-height: 72px;
      font-size: 20px;
      background-color: #162E49;
      border-radius: 0px 8px 8px 0px;
      border: 1px solid #0296F4;
      position: absolute;
      right: -19px;
      top: 50%;
      margin-top: -36px;
      z-index: -1;

      span {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: -3px;
        margin-top: -10px;
        z-index: 1;

        img {
          display: block;
          width: 20px;
          height: 20px;
        }
      }
    }

    .rote {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }

  .subBlock {
    display: flex;
    flex-grow: 1;
    position: relative;
    z-index: 1;
  }

  .map {
    flex-grow: 1;
    overflow: hidden;

    &.is-animate {
      overflow: hidden;
    }

    &.is-FixedToolbar {
      margin-top: 0px;
    }

    & ::v-deep .amap-logo,
    & ::v-deep .amap-copyright {
      display: none !important;
      z-index: -1;
    }

    & ::v-deep .amap-marker-label {
      border: 1px solid #CCC;
      border-radius: 2px;
      background-color: rgba(#FFF, .85);
      font-weight: 600;
    }
  }

  .toggleNav {
    position: absolute;
    left: 250px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 102;
    font-size: 56px;
    cursor: default;

    &.isHide {
      left: 0;
    }
  }

  .v-detail {
    position: absolute;
    z-index: 111;
    left: 0px;
    bottom: 0;
    //height: 250px;
    border-top: 1px solid #DCDFE6;
    width: calc(100% - 0px);
    box-sizing: content-box;
    background-color: #FFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);

    &.isHide {
      left: 0;
      width: calc(100%);
    }

    .v-pager {
      $color: rgba(0, 0, 0, .95);
      text-align: right;
      background-color: #F4F4F8;
      padding: 0;

      & ::v-deep span:not([class*=suffix]),
      & ::v-deep button,
      & ::v-deep .el-pager,
      & ::v-deep .el-pager li,
      & ::v-deep .el-input,
      & ::v-deep .el-input input {
        height: 20px;
        line-height: 20px;
      }

      & ::v-deep .el-pagination__total {
        float: left;
        color: $color;
        font-size: 12px;
        padding-left: 8px;
      }

      & ::v-deep .el-pagination__sizes {
        float: left;
        color: $color;
      }

      & ::v-deep .el-input__inner {
        border-radius: 2px !important;
        color: $color;
      }

      & ::v-deep .el-pagination__jump {
        color: $color;
      }
    }

    .el-table {
      border-top: none;
      border-left: none;

      & ::v-deep .el-table__header {
        th {
          padding: 8px 0;
          background: #263346;
          color: #a1a1a1;
          font-weight: 600;
          border-bottom: none;
          border-right: none;
        }
      }

      & ::v-deep .el-table__body-wrapper {
        background-color: #263346;
      }

      & ::v-deep .el-table__body {
        td {
          background-color: #263346;
          color: #fff;
          border: none !important;
        }
      }

      &::before {
        background-color: #126585;
      }

      &::after {
        background-color: #126585;
      }

    }

    .table-check {
      position: absolute;
      z-index: 111;
      right: 10px;
      top: 8px;
      background: #263346;

      .el-button {
        padding: 0 4px;

        & ::v-deep span {
          font-size: 12px;
          color: #fff;
        }

        & ::v-deep i {
          font-size: 13px;
        }
      }

      .table-exchange {
        height: 20px;
        width: 20px;
        margin-left: 8px;
        vertical-align: middle;
        cursor: pointer;
      }

      .el-checkbox {
        vertical-align: middle;

        & ::v-deep .el-checkbox__label {
          font-size: 12px;
          color: #fff;
        }
      }
    }

    .toggleTable {
      position: absolute;
      left: 50%;
      top: -16px;
      transition: all 0.3s ease;
      transform: translateX(-50%) translateY(0);
      z-index: 102;
      font-size: 56px;
      cursor: default;
    }
  }


  .v-pano.is-panoopen {
    flex-grow: 1;
    position: relative;
    width: 0%;
    margin-top: 40px;
    z-index: 109;
    overflow: hidden;

    &:hover {
      z-index: 109;
    }

    .closeBtn {
      position: absolute;
      top: 20px;
      left: 100px;
      z-index: 5;
      font-size: 16px;
      padding: 8px 9px;
      border-radius: 2px;
      opacity: .7;
      background-color: $--color-danger;
      color: #FFF;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .v-pano {
    .v-wrap {
      height: 105%;

      .box {
        width: 100%;
        height: 100%;
      }
    }

    .nofollow {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .map--tip {
    position: absolute;
    z-index: 151;
    left: 0;
    top: 6px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;

    &.isNavShow {
      left: 280px;
    }

    $bk: rgba(255, 255, 255, 0.7);
    $color: #607D8B;
    $border: #CFD8DC;
    $bk-highlight: #BBDEFB;
    $bk-highlight-color: $--color-primary;

    .countdown {
      min-width: 120px;
      padding: 0 12px;
      background-color: $bk;
      color: $color;
      user-select: none;

      .countdown__number {
        color: $--color-primary;
        margin-right: 2px;
      }
    }

    .tip__item {
      background-color: $bk;
      color: $bk-highlight-color;
      padding: 0 12px;
      white-space: nowrap;
      width: fit-content;
      border-radius: 4px;

      .item__action {
        color: $color;
        margin-left: 2px;
        cursor: pointer;
      }

      .item__split {
        height: 1px;
        display: block;
        background-color: $border;
      }

      & ::v-deep span {
        color: $color;
      }
    }

    p:not(:first-child) {
      margin-top: 5px;
    }
  }

  & ::v-deep .amap-logo {
    bottom: 2px !important;
  }

  & ::v-deep .amap-overlay-text-container {
    font-size: 12px;
    background-color: #FFF;
    border-color: #1E88E5;
    padding: 4px 4px;
    opacity: 0.8;
    //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }

  & ::v-deep .amap-scalecontrol {
    z-index: 99;
  }
}

.badge-item {
  height: 28px;
  max-width: 90px;
  display: flex;
  align-items: center;

  .icon {
    font-size: 14px;
    margin-right: 2px;
    margin-left: -2px;
    color: #f44336;
    animation: flash infinite 1s linear;
  }

  & .el-button--small.is-round {
    padding: 6px 16px;
  }
}

@keyframes flash {
  10% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }
}

.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 201;
  position: fixed;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: $--color-text-regular;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }

    &.active {
      color: $--color-primary;
    }
  }
}

.mapPoi {
  position: absolute;
  z-index: 110;
  right: 10px;
  top: 140px;
}

.square {
  height: 14px;
  width: 14px;
  display: block;
  border-radius: 2px;
  background-color: #BDBDBD;
  margin-left: 4px;

  &.isOnline {
    background-color: #4CAF50;
  }
}

.imgcolumn {
  img {
    margin-left: 4px;
    vertical-align: text-top;
    margin-top: -1px;
  }
}


.pointer {
  cursor: pointer;
}

.tipGroup {
  width: 100%;
  position: absolute;
  top: 0px;
}

.gl {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 10px;
  top: 20%;
  z-index: 3;
  box-shadow: #0000003d 1px 2px 1px;
  background: url(../../assets/images/gl-navi-control-pc4.png) -110px 1px / 266px no-repeat #fff;
}

.d3 {
  background: url(../../assets/images/gl-navi-control-pc4.png) -188px 1px / 266px no-repeat #fff;
}

.tracktable {
  & ::v-deep .caret-wrapper {
    height: 22px;

    .ascending {
      top: 0;
    }

    .descending {
      bottom: 0;
    }
  }
}

.v-poi-tool {
  position: absolute;
  top: 90px;
  right: 40px;
  z-index: 2;

  p {
    background-color: #1890ff;
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
  }

  span {
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #1890ff;
    color: #1890ff;
    border-radius: 15px;
    padding: 7px 20px;
    margin-left: 20px;
  }

  span:hover {
    background-color: #1890ff;
    color: #fff;
  }
}
</style>